<template>
  <div
    id="shipping"
    class="tab-pane active min-h-[400px] bg-transparent"
    role="tabpanel"
    aria-labelledby="shipping-tab"
  >
    <loading-spinner type="controller" :loading="loading" />
    <div class="intro-y p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      ></div>
      <div>
        <div
          class="overflow-x-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 py-1.5"
        >
          <div v-for="row of rows" :key="row" class="col-span-1">
            <accordion
              :title="{
                ar: row.name_ar,
                en: row.name_en
              }"
            >
              <div
                v-for="paymentMethod of row.paymentMethods"
                :key="paymentMethod"
                class="py-2 border-b"
              >
                <div class="flex justify-between gap-3 sm:max-w-[95%] mx-auto">
                  <h3 class="text-sm">
                    {{ paymentMethod[isRTL ? 'name_ar' : 'name_en'] }}
                  </h3>
                  <div>
                    <toggle
                      :active="
                        getStatus(row.gateway_id, paymentMethod.payment_id)
                      "
                      :action="
                        () =>
                          setStateChange(
                            row.gateway_id,
                            paymentMethod.payment_id
                          )
                      "
                    />
                  </div>
                </div>
              </div>
              <template #actions>
                <button
                  type="button"
                  v-if="!loading && isDirty(row.gateway_id)"
                  class="text-green-500 hover:underline font-semibold text-base ltr:-intro-x rtl:intro-x"
                  @click="handleSave(row.gateway_id)"
                >
                  {{ i18n('common.save') }}
                </button>
              </template>
            </accordion>
          </div>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.gateways')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full"
          ></app-empty-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'settings-gateways-tab',
  data() {
    return {
      columns: [
        {
          name: 'nameEn',
          field: 'nameEn',
          label: 'iam.fields.nameEn',
          align: 'center'
        },
        {
          name: 'nameAr',
          field: 'nameAr',
          label: 'iam.fields.nameAr',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ],
      statusChanges: []
    }
  },
  computed: {
    ...mapGetters({
      rows: 'setting/gateWays/rows',
      loading: 'setting/gateWays/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    changed() {
      if (Object.keys(this.statusChanges).length) {
        return Object.values(this.statusChanges).reduce((acc, gateway) => {
          acc[gateway.gateway_id] = this
          return acc
        }, {})
      }
      return {}
    }
  },
  async beforeMount() {
    await this.doFetch()
    this.statusChanges = this.rows.reduce((acc, row) => {
      const payments = row.paymentMethods.map((payment) => ({
        id: payment.payment_id,
        isActive: payment.isActive
      }))
      acc.push({ gateway_id: row.gateway_id, payments })
      return acc
    }, [])
  },
  methods: {
    ...mapActions({
      doFetch: 'setting/gateWays/doFetch',
      doToggleStatus: 'setting/gateWays/doToggleGatewaysStatus'
    }),
    async toggleStatus(gatewayId, ids, isActive) {
      await this.doToggleStatus({ gatewayId, ids, isActive })
    },
    setStateChange(gateway_id, payment_id) {
      this.statusChanges = this.statusChanges.map((row) => {
        if (row.gateway_id === gateway_id) {
          row.paymentMethods = row.payments.map((payment) => {
            if (payment.id === payment_id) {
              payment.isActive = !payment.isActive
            }
            return payment
          })
        }
        return row
      })
    },
    isDirty(id) {
      const originalStatus = this.rows.find((row) => row.gateway_id === id)
      const currentStatus = this.statusChanges.find(
        (row) => row.gateway_id === id
      )
      return originalStatus.paymentMethods?.some(
        (val, i) => currentStatus?.payments[i].isActive !== val.isActive
      )
    },
    getStatus(gateway_id, payment_id) {
      return this.statusChanges
        .find((row) => row.gateway_id === gateway_id)
        .payments?.find((payment) => payment.id === payment_id).isActive
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    async handleSave(gatewayId) {
      const row = this.statusChanges.find((row) => row.gateway_id === gatewayId)
      if (!row) return
      try {
        await this.doToggleStatus(row)
        this.statusChanges[gatewayId] = {}
      } catch (err) {
      }
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>

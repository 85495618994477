<template>
  <div
    id="general"
    role="tabpanel"
    aria-labelledby="general-tab"
    class="tab-pane active"
  >
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <section class="relative">
            <!-- <loading-spinner type="controller" :duration="1000" :size="40" color="#4469A8" :loading="findLoading" /> -->
            <!-- <LoadingIcon icon="grid" color="#2D3748" style="margin: 0 4px;" /> -->
            <div
              style="width: 100%; height: 50vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <loading-spinner
                type="controller"
                :duration="1000"
                :size="40"
                color="#4469A8"
                :loading="findLoading"
              />
            </div>

            <div v-if="model">
              <div class="grid grid-cols-12">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-minBalance" class="form-label">
                    {{ i18n('settings.settingsTab.minBalance') }}
                  </label>
                  <input
                    id="form-minBalance"
                    type="number"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.minimumWalletBalance"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-IOSVersion" class="form-label">
                    {{ i18n('settings.settingsTab.IOSVersion') }}
                  </label>
                  <input
                    id="form-IOSVersion"
                    type="number"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.IOSVersion"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-androidVersion" class="form-label">
                    {{ i18n('settings.settingsTab.androidVersion') }}
                  </label>
                  <input
                    id="form-androidVersion"
                    type="number"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.androidVersion"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-androidVersion" class="form-label">
                    {{ i18n('settings.settingsTab.daily_purchase_limit') }}
                  </label>
                  <input
                    id="form-androidVersion"
                    type="number"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.daily_purchase_limit"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-androidVersion" class="form-label">
                    {{ i18n('settings.settingsTab.exchange_amount') }}
                  </label>
                  <input
                    id="form-androidVersion"
                    type="number"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.exchange_amount"
                  />
                </div>
              </div>
              <div class="flex justify-start items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SettingModel } from '@/store/setting/setting-model'

const { fields } = SettingModel
const formSchema = new FormSchema([
  fields.IOSVersion,
  fields.androidVersion,
  fields.minimumWalletBalance,
  fields.emailTemplate,
  fields.daily_purchase_limit,
  fields.exchange_amount
])

export default defineComponent({
  name: 'settings-general-tab',
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'setting/general/record',
      findLoading: 'setting/general/findLoading',
      saveLoading: 'setting/general/saveLoading'
    })
  },
  async mounted() {
    await this.doFind()
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doSave: 'setting/general/doSave',
      doFind: 'setting/general/doFind'
      // doUpdate: 'setting/general/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.form) {
        const value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'settings.settingsTab.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
      // this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { ...values } = formSchema.cast(this.model)
      await this.doSave({
        ...values,
        IOSVersion: parseFloat(values.IOSVersion),
        androidVersion: parseFloat(values.androidVersion),
        exchange_amount: parseFloat(values.exchange_amount),
        daily_purchase_limit: parseFloat(values.daily_purchase_limit)
      })
    }
  }
})
</script>

<template>
  <div
    id="template"
    role="tabpanel"
    aria-labelledby="template-tab"
    class="tab-pane active"
  >
    <div class="grid grid-cols-12 gap-6 mt-5">
      <vLottiePlayer
        :animation-duration="duration"
        :dot-size="15"
        :dots-num="3"
        :animationData="require('@/assets/lottie/playstation-controller.json')"
      ></vLottiePlayer>
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <section class="relative">
            <!-- <loading-spinner type="controller" :duration="1000" :size="40" color="#4469A8" :loading="findLoading" /> -->
            <!-- <LoadingIcon icon="grid" color="#2D3748" style="margin: 0 4px;" /> -->
            <div
              style="width: 100%; height: 50vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <loading-spinner
                type="controller"
                :duration="1000"
                :size="40"
                color="#4469A8"
                :loading="findLoading"
              />
            </div>

            <div v-if="model">
              <div class="grid grid-cols-12">
                <div class="col-span-12" :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'">
                  <label for="form-emailTemplate" class="form-label">
                    {{ i18n('settings.settingsTab.emailTemplate') }}
                  </label>
                  <textarea
                    id="form-emailTemplate"
                    rows="22"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.emailTemplate"
                  />
                </div>
              </div>

              <div class="flex justify-start items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SettingModel } from '@/store/setting/setting-model'

const { fields } = SettingModel
const formSchema = new FormSchema([
  fields.minimumWalletBalance,
  fields.emailTemplate
])

export default {
  name: 'settings-template-tab',
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'setting/general/record',
      findLoading: 'setting/general/findLoading',
      saveLoading: 'setting/general/saveLoading'
    })
  },
  async mounted() {
    await this.doFind()
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doSave: 'setting/general/doSave',
      doFind: 'setting/general/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.form) {
        const value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'settings.settingsTab.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { ...values } = formSchema.cast(this.model)
      await this.doSave(values)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
<style lang="scss" scoped>
// .app-toggle-button {
//   position: absolute;
//   right: 2.083vw;
//   top: 60px;
// }
// @media (max-width: 1439px) {
//   .app-toggle-button {
//     // right: calc(10px + (30 - 10) * ((100vw - 320px) / (1440 - 320)) !important);
//     top: calc(20px + (60 - 20) * ((100vw - 320px) / (1440 - 320))) !important;
//   }
// }
</style>

<template>
  <div>
    <accordion :title="{ en: title, ar: title }">
      <div class="flex gap-5 flex-col items-center mb-5">
        <membership-form
          :title="{ en: 'Minimum Discount', ar: 'اقل خصم' }"
          :initMin="min"
          :initMax="max"
          :initType="type"
          :initBreakpoint="breakpoint"
          :id="id"
        />
      </div>
    </accordion>
  </div>
</template>

<script>
import membershipForm from '@/views/settings/memberships/membership-form.vue'

export default {
  components: { 'membership-form': membershipForm },
  props: {
    id: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    breakpoint: {
      type: Number,
      required: true
    }
  }
}
</script>

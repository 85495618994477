
<template>
  <div class="flex flex-col gap-1.5 max-w-full w-full px-3 xl:px-5">
    <div
      class="flex rounded-2xl shadow-md border overflow-hidden bg-white text-[11px] w-full mx-auto flex-1 mb-4"
    >
      <button
        class="font-semibold whitespace-nowrap w-1/2 py-1.5 px-3 ltr:pl-3 rtl:pr-3"
        :class="type === 'fixed' ? 'bg-gray-100' : 'text-black'"
        @click="handleType('fixed')"
        :disabled="type === 'fixed'"
      >
        {{ t('memberships.fixed') }}
      </button>
      <button
        class="font-semibold whitespace-nowrap w-1/2 py-1.5 px-3 ltr:pr-3 rtl:pl-3"
        :class="type === 'percent' ? 'bg-gray-100' : 'text-black'"
        @click="handleType('percent')"
        :disabled="type === 'percent'"
      >
        {{ t('memberships.percentage') }}
      </button>
    </div>
    <Form
      @submit="submit"
      :validation-schema="schema"
      :initial-values="{ min, max, breakpoint }"
    >
      <div
        class="flex flex-col sm:flex-row sm:justify-around sm:flex-wrap gap-3 md:gap-4 flex-1 mb-5"
      >
        <div class="flex-1 min-h-[85px]">
          <!-- label -->
          <label for="min" class="mx-1.5 text-[0.9rem]">{{
            t('memberships.min')
          }}</label>
          <!-- input -->
          <div
            class="text-sm rounded-2xl bg-white flex shadow-md border overflow-hidden mb-1 min-w-[fit-content]"
            :class="[type === 'fixed' ? 'flex-row-reverse' : 'flex-row']"
          >
            <span
              class="bg-gray-100 px-2.5 py-1.5 flex items-center justify-center w-8"
            >
              {{ type === 'fixed' ? t('common.le') : '%' }}
            </span>
            <Field
              type="number"
              name="min"
              v-model="min"
              class="remove-number-arrows focus-visible:outline-none flex-1 px-2"
            />
          </div>
          <ErrorMessage class="text-primary-3 pt-2" name="min" />
        </div>

        <div class="flex-1 min-h-[85px]">
          <!-- label -->
          <label for="max" class="mx-1.5 text-[0.9rem]">{{
            t('memberships.max')
          }}</label>
          <!-- input -->
          <div
            class="text-sm rounded-2xl bg-white flex shadow-md border overflow-hidden min-w-[fit-content]"
            :class="[type === 'fixed' ? 'flex-row-reverse' : 'flex-row']"
          >
            <span
              class="bg-gray-100 px-2.5 py-1.5 flex items-center justify-center w-8"
            >
              {{ type === 'fixed' ? t('common.le') : '%' }}
            </span>
            <Field
              type="number"
              name="max"
              v-model="max"
              class="remove-number-arrows focus-visible:outline-none flex-1 px-2"
            />
          </div>
          <ErrorMessage class="text-primary-3 pt-2" name="max" />
        </div>
        <div class="flex-1 min-h-[85px]">
          <label for="breakpoint" class="mx-1.5 text-[0.9rem]">{{
            t('memberships.maxStart')
          }}</label>
          <div
            class="text-sm rounded-2xl bg-white flex shadow-md border overflow-hidden mb-1 min-w-[fit-content]"
            :class="[type === 'fixed' ? 'flex-row-reverse' : 'flex-row']"
          >
            <Field
              type="number"
              name="breakpoint"
              v-model="breakpoint"
              class="remove-number-arrows focus-visible:outline-none flex-1 px-2 py-1.5"
            />
          </div>
          <ErrorMessage class="text-primary-3 pt-2" name="breakpoint" />
        </div>
      </div>
      <div class="flex justify-end px-2 pb-3">
        <button
          class="btn bg-theme-31 text-white px-5 mx-3 focus:!ring-offset-0 focus:!ring-transparent focus-visible:!ring-offset-0 focus-visible:!ring-transparent focus:appearance-none active:scale-95 transition-all duration-200"
        >
          {{ t('common.apply') }}
        </button>
      </div>
    </Form>
  </div>
</template>

<script >
/* eslint-disable */
import { ref } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  data() {
    return {
      min: ref(this.$props.initMin),
      max: ref(this.$props.initMax),
      breakpoint: ref(this.$props.initBreakpoint),
      type: ref(this.$props.initType)
    }
  },
  props: {
    id: {
      required: true
    },
    title: {
      type: Object,
      required: true
    },
    initMax: {
      type: Number,
      required: true
    },
    initBreakpoint: {
      type: Number,
      required: false
    },
    initMin: {
      type: Number,
      required: true
    },
    initType: {
      type: String,
      required: true,
      default: 'fixed'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      locale: 'layout/locale'
    }),
    schema() {
      const max = this.max
      const min = this.min
      const type = this.type
      return yup.object({
        min: yup
          .number()
          .required(
            this.t('validation.required', {
              name: this.t('memberships.form.min')
            })
          )
          .min(
            0,
            this.t('validation.positive', {
              name: this.t('memberships.form.min')
            })
          )
          .max(
            type === 'fixed' ? max : Math.min(100, max),
            this.t('validation.max', {
              max: this.t('memberships.form.min'),
              number: this.t('memberships.form.max')
            })
          )
          .typeError(
            this.t('validation.required', {
              name: this.t('memberships.form.min')
            })
          ),
        max: yup
          .number()
          .required(
            this.t('validation.required', {
              name: this.t('memberships.form.max')
            })
          )
          .min(
            min,
            min > 0
              ? this.t('validation.min', {
                  name: this.t('memberships.form.max'),
                  number: this.t('memberships.form.min')
                })
              : this.t('validation.positive', {
                  name: this.t('memberships.form.max')
                })
          )
          .max(
            type === 'fixed' ? Infinity : 100,
            type !== 'fixed'
              ? this.t('validation.max', {
                  name: this.t('memberships.form.max'),
                  number: 100
                })
              : undefined
          )
          .typeError(
            this.t('validation.required', {
              name: this.t('memberships.form.max')
            })
          ),
        breakpoint: yup
          .number()
          .required(
            this.t('validation.required', {
              name: this.t('memberships.form.maxStart')
            })
          )
          .min(
            0,
            this.t('validation.positive', {
              name: this.t('memberships.form.maxStart')
            })
          )
          .typeError(
            this.t('validation.required', {
              name: this.t('memberships.form.maxStart')
            })
          )
      })
    }
  },
  methods: {
    ...mapActions({
      updateMemberships: 'setting/memberships/updateMemberships'
    }),

    async submit(values) {
      const res = {}
      res.max_discount = Number(values.max)
      res.min_discount = Number(values.min)
      res.target_amount = Number(values.breakpoint)
      res.id = this.$props.id
      res.adjustment_method = this.type
      await this.updateMemberships({ memberships: res })
    },
    handleType(type) {
      this.type = type
    },
    t(key, args) {
      return this.$t(key, args)
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage
  }
}
</script>

<style>
.remove-number-arrows::-webkit-inner-spin-button,
.remove-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-number-arrows {
  -moz-appearance: textfield;
}
</style>

<template>
  <div class="min-h-[400px] bg-transparent">
    <!-- <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mb-5">{{ i18n('menu.Memberships') }}</h2>
    </div> -->
    <div
      class="w-full min-w-[unset] grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-wrap xl:flex-nowrap gap-2"
    >
      <loading-spinner type="controller" :loading="loading" />
      <membership-card
        v-for="row of rows.rows"
        :key="row.id"
        :id="row.id"
        :title="row.product_type"
        :max="row.max_discount"
        :min="row.min_discount"
        :type="row.adjustment_method"
        :breakpoint="row.target_amount || 0"
        class="col-span-1 lg:flex-1"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import membershipCard from './membership-card.vue'
export default {
  async beforeMount() {
    await this.doFetch()
  },
  computed: {
    ...mapGetters({
      rows: 'setting/memberships/rows',
      loading: 'setting/memberships/loading',
      isRTL: 'layout/isRTL'
    })
  },
  methods: {
    ...mapActions({
      doFetch: 'setting/memberships/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  components: {
    'membership-card': membershipCard
  }
}
</script>

<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="settings.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: Settings Tabs -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <a
          id="general-tab"
          data-toggle="tab"
          data-target="#general"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'general' ? 'active' : ''"
          role="tab"
          aria-controls="general"
          aria-selected="true"
          @click="tab = 'general'"
        >
          {{ i18n('settings.tabs.general') }}
        </a>
        <a
          id="template-tab"
          data-toggle="tab"
          data-target="#template"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'template' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'template'"
        >
          {{ i18n('settings.tabs.emailTemplate') }}
        </a>
        <a
          id="privacy-tab"
          data-toggle="tab"
          data-target="#privacy"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'privacy' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'privacy'"
        >
          {{ i18n('settings.tabs.privacyPolicy') }}
        </a>
        <a
          id="terms-tab"
          data-toggle="tab"
          data-target="#terms"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'terms' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'terms'"
        >
          {{ i18n('settings.tabs.termsAndConditions') }}
        </a>
        <a
          id="gateways-tab"
          data-toggle="tab"
          data-target="#gateways"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'gateways' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'gateways'"
        >
          {{ i18n('settings.tabs.gateways') }}
        </a>
        <a
          id="memberships-tab"
          data-toggle="tab"
          data-target="#memberships"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'memberships' ? 'active' : ''"
          role="tab"
          aria-selected="false"
          @click="tab = 'memberships'"
        >
          {{ i18n('settings.tabs.memberships') }}
        </a>
      </div>
    </div>
    <!-- END:  Settings Tabs -->
    <div class="intro-y tab-content mt-5">
      <div
        id="general"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="general-tab"
        v-if="tab == 'general'"
      >
        <GeneralTab />
      </div>
      <div
        id="template"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="template-tab"
        v-if="tab == 'template'"
      >
        <TemplateTab />
      </div>
      <div
        id="privacy"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="privacy-tab"
        v-if="tab == 'privacy'"
      >
        <privacyTab />
      </div>
      <div
        id="terms"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="terms-tab"
        v-if="tab == 'terms'"
      >
        <termsAndConditionsTab />
      </div>
      <div
        id="gateways"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="gateways-tab"
        v-if="tab == 'gateways'"
      >
        <GatewayTab />
      </div>
      <div
        id="memberships"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="memberships-tab"
        v-if="tab == 'memberships'"
      >
        <MembershipsTab />
      </div>
    </div>
  </div>
</template>

<script>
import GeneralTab from '@/views/settings/general/general-tab.vue'
import TemplateTab from '@/views/settings/template/template-tab.vue'
import GatewayTab from '@/views/settings/gateways/gateway-tab.vue'
import MembershipsTab from '@/views/settings/memberships/membership-tab.vue'
import termsAndConditionsTab from './terms-and-conditions/terms-and-conditions-tab.vue'
import privacyTab from './privacy/privacy-tab.vue'

export default {
  components: {
    GeneralTab,
    TemplateTab,
    GatewayTab,
    MembershipsTab,
    termsAndConditionsTab,
    privacyTab
  },
  data() {
    return {
      tab: 'general'
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
